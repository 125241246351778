export default {
    light: {
        logo: {
            imgName: 'Logo',
            uri: require('../assets/images/logo.svg')
        },
        search: {
            imgName: 'Search',
            uri: require('../assets/images/search.svg')
        },
        homeIcon: {
            imgName: 'Home',
            uri: require('../assets/images/home.svg')
        },
        homeIconSelected: {
            imgName: 'Home[selected]',
            uri: require('../assets/images/home-selected.svg')
        },
        rankingIcon: {
            imgName: 'Ranking',
            uri: require('../assets/images/ranking.svg')
        },
        rankingIconSelected: {
            imgName: 'Ranking[selected]',
            uri: require('../assets/images/ranking-selected.svg')
        },
        resultIcon: {
            imgName: 'Result',
            uri: require('../assets/images/result.svg')
        },
        resultIconSelected: {
            imgName: 'Result[selected]',
            uri: require('../assets/images/result-selected.svg')
        },
        recommendIcon: {
            imgName: 'Recommend',
            uri: require('../assets/images/recommend.svg')
        },
        recommendIconSelected: {
            imgName: 'Recommend[selected]',
            uri: require('../assets/images/recommend-selected.svg')
        },
        newLabel: {
            imgName: 'new',
            uri: require('../assets/images/new.svg')
        },
        postCompleted: {
            imgName: 'completed',
            uri: require('../assets/images/complete.svg')
        }
    },
    dark: {
        logo: {
            imgName: 'Logo',
            uri: require('../assets/images/logo.svg')
        },
        search: {
            imgName: 'Search',
            uri: require('../assets/images/search.svg')
        },
        homeIcon: {
            imgName: 'Home',
            uri: require('../assets/images/home.svg')
        },
        homeIconSelected: {
            imgName: 'Home[selected]',
            uri: require('../assets/images/home-selected.svg')
        },
        rankingIcon: {
            imgName: 'Ranking',
            uri: require('../assets/images/ranking.svg')
        },
        rankingIconSelected: {
            imgName: 'Ranking[selected]',
            uri: require('../assets/images/ranking-selected.svg')
        },
        resultIcon: {
            imgName: 'Result',
            uri: require('../assets/images/result.svg')
        },
        resultIconSelected: {
            imgName: 'Result[selected]',
            uri: require('../assets/images/result-selected.svg')
        },
        recommendIcon: {
            imgName: 'Recommend',
            uri: require('../assets/images/recommend.svg')
        },
        recommendIconSelected: {
            imgName: 'Recommend[selected]',
            uri: require('../assets/images/recommend-selected.svg')
        },
        newLabel: {
            imgName: 'new',
            uri: require('../assets/images/new.svg')
        },
        postCompleted: {
            imgName: 'completed',
            uri: require('../assets/images/complete.svg')
        }
    },
    disney: {
        logo: {
            imgName: 'Logo',
            uri: require('../assets/images/disney/dc_bnr.jpg')
        },
        search: {
            imgName: 'Search',
            uri: require('../assets/images/disney/search.svg')
        },
        homeIcon: {
            imgName: 'Home',
            uri: require('../assets/images/disney/home.svg')
        },
        homeIconSelected: {
            imgName: 'Home[selected]',
            uri: require('../assets/images/disney/home-selected.svg')
        },
        rankingIcon: {
            imgName: 'Ranking',
            uri: require('../assets/images/disney/ranking.svg')
        },
        rankingIconSelected: {
            imgName: 'Ranking[selected]',
            uri: require('../assets/images/disney/ranking-selected.svg')
        },
        resultIcon: {
            imgName: 'Result',
            uri: require('../assets/images/disney/result.svg')
        },
        resultIconSelected: {
            imgName: 'Result[selected]',
            uri: require('../assets/images/disney/result-selected.svg')
        },
        recommendIcon: {
            imgName: 'Recommend',
            uri: require('../assets/images/disney/recommend.svg')
        },
        recommendIconSelected: {
            imgName: 'Recommend[selected]',
            uri: require('../assets/images/disney/recommend-selected.svg')
        },
        newLabel: {
            imgName: 'new',
            uri: require('../assets/images/disney/new.svg')
        },
        postCompleted: {
            imgName: 'completed',
            uri: require('../assets/images/disney/complete.svg')
        }
    }
};
