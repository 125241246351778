export default {
    // light:本番, dark:未使用
    light: {
        HeaderHeight: 70,
        SearchHeaderHeight: 76,
        HeaderImgWidth: 135,
        HeaderImgHeight: 47,
        PosTop: '15px',
        DrawerTop: '70px',
        Display: 'flex',
        MenuTxt: 'オススメ',
        RecommendDisplay: 'flex',
        HeaderTxt: '',
        LoginTitle: '',
        LoginPeriod: '',
        TenantNamePlaceholder: '店名を入力してください（SC名の入力は不要です）'
    },
    dark: {
        HeaderHeight: 70,
        SearchHeaderHeight: 76,
        HeaderImgWidth: 135,
        HeaderImgHeight: 47,
        PosTop: '15px',
        DrawerTop: '70px',
        Display: 'flex',
        MenuTxt: 'オススメ',
        RecommendDisplay: 'flex',
        HeaderTxt: '',
        LoginTitle: '',
        LoginPeriod: '',
        TenantNamePlaceholder: '店名を入力してください（SC名の入力は不要です）'
    },
    disney: {
        HeaderHeight: 90,
        SearchHeaderHeight: 96,
        HeaderImgWidth: 140,
        HeaderImgHeight: 70,
        PosTop: '10px',
        DrawerTop: '90px',
        Display: 'none',
        MenuTxt: 'ツール',
        RecommendDisplay: 'flex',
        HeaderTxt:
            '11月1日（水）から12月25日（月）までの間で、『Disney100 AEONMALL Dreaming Christmas ディスプレイコンテスト』を開催！ディズニー関連商品をお取り扱いの店舗さまを対象とした特別なディスプレイコンテスト！期間中に売り場でのディスプレイ演出を行っていただき、入賞された各店舗様には、特別な賞品を用意しておりますので、対象のディスプレイ・店舗画像をご投稿ください。',
        LoginTitle: '『Disney100 AEONMALL Dreaming Christmas ディスプレイコンテスト』公式ページ',
        LoginPeriod: '応募期間：11月1日（水）～12月25日（月）',
        TenantNamePlaceholder: '店名を入力してください'
    }
};
